import React from 'react';
import { Col, Row, Typography } from 'antd';
import { LinkCard } from '../../../cards/link-card/link-card';
import css from './hero.module.less';
import Venuespic from '../../../../images/venues.jpg';
import Cateringpic from '../../../../images/catering.jpg';
import Virtualpic from '../../../../images/virtual.jpg';
import Audiopic from '../../../../images/audiovisual.jpg';
import Destinationpic from '../../../../images/destinations.jpg';
import Event from '../../../../images/accommodation.jpeg';

const { Title } = Typography;

export const Hero = () => (
  <section>

    <Title level={3} className={`${css.title} text-center`}>
      Find the best event venues and suppliers, curated by real event professionals
    </Title>
    <Row gutter={[32, 32]} className={css.cards}>
      <Col flex="1 1 350px">
        <LinkCard
          title="Venues"
          link="/search/?productTypes=UHJvZHVjdFR5cGU6NA%3D%3D"
          image={Venuespic}
        />
      </Col>
      <Col flex="1 1 350px">
        <LinkCard
          title="Catering"
          link="/search/?productTypes=UHJvZHVjdFR5cGU6MTA%3D"
          image={Cateringpic}
        />
      </Col>
      <Col flex="1 1 350px">
        <LinkCard
          title="Virtual Event Platforms"
          image={Virtualpic}
          link="/search/?productTypes=UHJvZHVjdFR5cGU6MTE%3D"
        />

      </Col>
      <Col flex="1 1 350px">
        <LinkCard
          title="Audiovisual"
          image={Audiopic}
          link="/search/?productTypes=UHJvZHVjdFR5cGU6MTQ%3D"
        />
      </Col>
    </Row>
    <Row gutter={[32, 32]} className={css.cards}>

      <Col flex="1 1 350px">
        <LinkCard
          title="Destinations"
          image={Destinationpic}
          link="/search/?productTypes=UHJvZHVjdFR5cGU6MTU%3D"
        />
      </Col>
      <Col flex="1 1 350px">
        <LinkCard
          title="Event Accommodation"
          image={Event}
          link="/search/?productTypes=UHJvZHVjdFR5cGU6MQ%3D%3D,UHJvZHVjdFR5cGU6Mg%3D%3D,UHJvZHVjdFR5cGU6Mw%3D%3D"
        />
      </Col>
    </Row>
  </section>

);
