import { Button, Card, Typography } from 'antd';
import React, { useState } from 'react';
import { FcApproval } from 'react-icons/all';
import { ContactModal } from '../contact-modal/contact-modal';
import { useShop } from '../../../graphql/static-hooks/useShop';
import css from './supplier-card.module.less';

const { Meta } = Card;
const { Title } = Typography;

export const SupplierCard = () => {
  const shop = useShop();
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Card
        className={css.card}
        cover={(
          <p className="text-center"><FcApproval /></p>
        )}
        actions={[
          <Button size="large" onClick={() => setIsModalVisible(true)}>Contact Us</Button>,
        ]}
      >

        <Meta
          title="Are you an elite event supplier?"
          description={`Join ${shop.name}`}
        />
      </Card>
      <ContactModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </>
  );
};
