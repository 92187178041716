import React, { useEffect, useState } from 'react';
import {
  Col, Divider, Row, Typography,
} from 'antd';
import { LinkCard } from '../cards/link-card/link-card';
import css from './blog.module.less';

const { Title } = Typography;

export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const a = [];

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: '{blogs { id name url link isVisible}}' }),
  };

  useEffect(() => {
    fetch(process.env.GATSBY_SALEOR_API_URL, requestOptions).then((res) => res.json()).then((result) => {
      setBlogs(result.data.blogs || []);
    });
  }, []);

  if (blogs.length < 1) {
    return null;
  }

  return (
    <section>
      <Title level={3} className={`${css.title} text-center`}>
        News, Articles, Podcasts, Videos and More
      </Title>

      <Row gutter={[32, 32]} className={css.cards}>
        {blogs.map((blog) => {
          if (blog.isVisible) {
            return (
              <Col flex="1 1 350px" key={blog.url}>
                <LinkCard
                  title={blog.name}
                  link={blog.link}
                  image={blog.url}
                  overlay="red"
                />
              </Col>
            );
          }
        })}
      </Row>
    </section>
  );
};
