import React from 'react';
import {
  Col, Divider, Row, Typography,
} from 'antd';
import { graphql } from 'gatsby';
import { BaseLayout } from '../components/layout/base/base-layout';
import { SEO } from '../components/layout/seo';
import { Testimonials } from '../components/layout/sections/testimonials/testimonials';
import { Hero } from '../components/layout/sections/hero/hero';
import { HelpCard } from '../components/cards/help-card/help-card';
import { CarouselCard } from '../components/cards/carousel-card/carousel-card';
import { LinkCard } from '../components/cards/link-card/link-card';
import css from './index.module.less';
import { SupplierCard } from '../components/cards/supplier-card/supplier-card';
import { Blog } from '../components/blog/blog';

const { Title } = Typography;

const HomePage = ({ data: { saleor: result } }) => {
  const collections = result.collections?.edges;

  const venues = result.venues?.edges;
  const villas = result.villas?.edges;
  const apartments = result.apartments?.edges;
  const catering = result.catering?.edges;

  const productSections = [venues, villas, catering, apartments];
  const supplierCardSectionIndex = 1;
  return (
    <BaseLayout>
      <SEO title="Home" />
      <Hero />
      <Testimonials />
      <Blog />
      {collections?.length > 0 ? (
        <section className={css.section}>
          <Divider />
          <Row gutter={[32, 48]}>
            {collections.map(({ node }) => (
              <Col xs={24} md={12} lg={8} key={node.id}>
                <LinkCard
                  title={node.name}
                  tags={[`${node.products.totalCount} Suppliers`]}
                  link={`/collection/${node.slug}`}
                  image={node.backgroundImage?.url}
                  overlay="red"
                />
              </Col>
            ))}
            <Col xs={24} md={12} lg={8} xxl={4}>
              <HelpCard />
            </Col>
            <Col xs={24} md={12} lg={8} xxl={4}>
              <LinkCard
                overlay="blue"
                link="/collections"
                title="More Collections"
              />
            </Col>
          </Row>
        </section>
      ) : null}
      {productSections.map((section, idx) => {
        if (section?.length > 0) {
          const item = section[0].node;
          const { productType } = section[0].node;
          return (
            <section className={css.section}>
              <Title level={2} className="text-center">
                {`${productType.name}`}
              </Title>
              <Divider />
              <Row gutter={[32, 48]} align="center">
                {section.map(({ node }) => (
                  <Col xs={24} md={12} lg={8} key={node.id}>
                    <CarouselCard
                      variantId={node.defaultVariant?.id}
                      variantCount={node.variants?.length}
                      productName={node.name}
                      attributes={node.attributes}
                      images={node.images}
                      productSlug={node.slug}
                      productType={node.productType}
                      productLocation={node.location}
                    />
                  </Col>
                ))}
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  xxl={idx === supplierCardSectionIndex ? 4 : 8}
                >
                  <LinkCard
                    overlay="blue"
                    image={item.images?.[0].url}
                    link={`/search/?productTypes=${productType.id}`}
                    title={`Search: ${productType.name}`}
                  />
                </Col>
                {idx === supplierCardSectionIndex && (
                  <Col xs={24} md={12} lg={8} xxl={4}>
                    <SupplierCard />
                  </Col>
                )}
              </Row>
            </section>
          );
        }
        return null;
      })}
    </BaseLayout>
  );
};

export const query = graphql`
    query products {
        saleor {
            collections(
                first: 5,
                filter: {published: PUBLISHED},
                sortBy: {direction: DESC, field: PUBLICATION_DATE}) {
                edges {
                    node {
                        id
                        name
                        slug
                        products {
                            totalCount
                        }
                        backgroundImage(size: 500) {
                            url
                        }
                    }
                }
            }
            apartments: products(
                first: 5,
                filter: {
                    productTypes: ["UHJvZHVjdFR5cGU6MQ=="],
                    isPublished: true,
                    attributes: [
                        {
                            slug: "homepage",
                            values: ["yes"]
                        }
                    ]
                },
                sortBy: {field: DATE, direction: DESC}) {
                edges {
                    node {
                        id
                        name
                        productType {
                            id
                            name
                            slug
                        }
                        slug
                        location {
                            geography
                            address {
                                city
                                postalCode
                            }
                        }
                        attributes {
                            attribute {
                                name
                                inputType
                            }
                            values {
                                name
                            }
                        }
                        defaultVariant {
                            id
                        }
                        variants {
                            id
                        }
                        images {
                            url (size: 500)
                            alt
                        }
                    }
                }
            }
            villas: products(
                first: 5,
                filter: {
                    productTypes: ["UHJvZHVjdFR5cGU6Mg=="],
                    isPublished: true,
                    attributes: [
                        {
                            slug: "homepage",
                            values: ["yes"]
                        }
                    ]
                },
                sortBy: {field: DATE, direction: DESC}) {
                edges {
                    node {
                        id
                        name
                        productType {
                            id
                            name
                            slug
                        }
                        slug
                        location {
                            geography
                            address {
                                city
                                postalCode
                            }
                        }
                        attributes {
                            attribute {
                                name
                                inputType
                            }
                            values {
                                name
                            }
                        }
                        defaultVariant {
                            id
                        }
                        variants {
                            id
                        }
                        images {
                            url (size: 500)
                            alt
                        }
                    }
                }
            }
            venues: products(
                first: 5,
                filter: {
                    productTypes: ["UHJvZHVjdFR5cGU6NA=="],
                    isPublished: true,
                    attributes: [
                        {
                            slug: "homepage",
                            values: ["yes"]
                        }
                    ]
                },
                sortBy: {field: DATE, direction: DESC}) {
                edges {
                    node {
                        id
                        name
                        productType {
                            id
                            name
                            slug
                        }
                        slug
                        location {
                            geography
                            address {
                                city
                                postalCode
                            }
                        }
                        attributes {
                            attribute {
                                name
                                inputType
                            }
                            values {
                                name
                            }
                        }
                        defaultVariant {
                            id
                        }
                        variants {
                            id
                        }
                        images {
                            url (size: 500)
                            alt
                        }
                    }
                }
            }
            catering: products(
                first: 5,
                filter: {
                    productTypes: ["UHJvZHVjdFR5cGU6NQ=="],
                    isPublished: true,
                    attributes: [
                        {
                            slug: "homepage",
                            values: ["yes"]
                        }
                    ]
                },
                sortBy: {field: DATE, direction: DESC}) {
                edges {
                    node {
                        id
                        name
                        productType {
                            id
                            name
                            slug
                        }
                        slug
                        location {
                            geography
                            address {
                                city
                                postalCode
                            }
                        }
                        attributes {
                            attribute {
                                name
                                inputType
                            }
                            values {
                                name
                            }
                        }
                        defaultVariant {
                            id
                        }
                        variants {
                            id
                        }
                        images {
                            url (size: 500)
                            alt
                        }
                    }
                }
            }
        }
    }
`;

export default HomePage;
